import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient, HttpParams} from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { NavigationService } from "../navigation.service";
import { NotificationService} from '../notification.service';




// ================= only for demo purpose ===========
const DEMO_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjhkNDc4MDc4NmM3MjE3MjBkYzU1NzMiLCJlbWFpbCI6InJhZmkuYm9ncmFAZ21haWwuY29tIiwicm9sZSI6IlNBIiwiYWN0aXZlIjp0cnVlLCJpYXQiOjE1ODc3MTc2NTgsImV4cCI6MTU4ODMyMjQ1OH0.dXw0ySun5ex98dOzTEk0lkmXJvxg3Qgz4ed";

const DEMO_USER: User = {
  id: "5b700c45639d2c0c54b354ba",
  displayName: "Watson Joyce",
  role: "SA",
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";
  APP_USER_TIME = "TIME_USER";
  APP_TIME_SESSION = 10;

  private readonly BASE_URL: string = this.baseService.HOST + '/oauth';
	httpOptions : any;

  constructor(
    //private datePipe: DatePipe,
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private baseService : BaseService,
    private navigationService : NavigationService,
    private notificationService: NotificationService
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }

  public signin(username, password) {
    /*return of({token: DEMO_TOKEN, user: DEMO_USER})
      .pipe(
        delay(1000),
        map((res: any) => {
          this.setUserAndToken(res.token, res.user, !!res);
          this.signingIn = false;
          console.log(res);
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );*/

    // FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER

     this.signingIn = true;
     return this.login(username, password)
       .pipe(
         map((res: any) => {
           return res;
         }),
         catchError((error) => {
           return throwError(error);
         })
       );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return of(DEMO_USER)
      .pipe(
        map((profile: User) => {
          this.setUserAndToken(this.getJwtToken(), profile, true);
          this.signingIn = false;
          return profile;
        }),
        catchError((error) => {
          return of(error);
        })
      );
    
    /*
      The following code get user data and jwt token is assigned to
      Request header using token.interceptor
      This checks if the existing token is valid when app is reloaded
    */

    // return this.http.get(`${environment.apiURL}/api/users/profile`)
    //   .pipe(
    //     map((profile: User) => {
    //       this.setUserAndToken(this.getJwtToken(), profile, true);
    //       return profile;
    //     }),
    //     catchError((error) => {
    //       this.signout();
    //       return of(error);
    //     })
    //   );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    //this.ls.setItem(this.APP_USER_TIME, null);
    localStorage.removeItem(this.APP_USER_TIME);
    localStorage.removeItem('currentData');
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
   
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getTime() {
    return this.ls.getItem(this.APP_USER_TIME);
  }

  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }

  setToken(token: String) {
    this.token = token;
    this.ls.setItem(this.JWT_TOKEN, token);
  }

  login(username : string, password : string): Observable<any> {
    let params = new HttpParams() ;
   
		params = params.set('grant_type', 'password');
		params = params.set('username', username);
		params = params.set('password', password);
		params = params.set('client_id', 'web_site');
		 // Http Headers
      this.httpOptions = {
			headers: this.baseService.createLoginHeader()
      }
     
		return this.http.post(this.BASE_URL + '/token',params,this.httpOptions)
		.pipe(map((res: any) => {
     
      sessionStorage.setItem('token', res.access_token);
     
      this.setToken(res.access_token);

      this.getUserById(res.user_id).subscribe(user => {

       if(user.status_user == 1){ // SOLO LO DEJAMOS ENTRAR SI ESTA ACTIVO
      // TODO - ESTO PUEDE NO SER NECESARIO


       localStorage.setItem('currentData', JSON.stringify({user: user, token: res.access_token}));
       sessionStorage.setItem('user', user.id)
       ///this.navigationService.menuOptions(); // cargamos las opciones que puede ver el usuario en el menu
      
       this.baseService.sendUpdate(user.authUser.roles)
       this.setUserAndToken(res.access_token, user, !!res);
       // new Date(time.setMinutes(time.getMinutes() + 20))
      
       let time = new Date(); // MOMENTO DEL LOGIN
       this.ls.setItem(this.APP_USER_TIME, new Date(time.setMinutes(time.getMinutes() + this.APP_TIME_SESSION)));
       
       this.notificationService.sucessLogin()
       this.signingIn = false;
       
       return res;	

       }else{
         this.notificationService.error("Lo sentimos, su usuario se encuentra inactivo");
       }
       
     }, (err) =>{
       console.log("PASO AL ERROR")
       this.notificationService.error(err);
       console.log(err);
     }); 

      
    }),catchError(this.handleError));
  }


  public handleError (error: Response | any) {
		
    if (error.status === 400) {
      //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
      return observableThrowError('Email o password es incorrecto, intente nuevamente');
    }
    if (error.status === 401) {
      return observableThrowError('No se pudo actualizar, su contraseña actual es incorrecta');
    }
    if (error.status === 403) {
      //   No se pudo actualizar, corriga los parámetros inválidos e intente nuevamente
      return observableThrowError('No posee permisos suficientes para esta accion, contacte con el administrado');
    }
    if (error.status === 404) {
      return observableThrowError('No se pudo actualizar, registro inexistente');
    }
    if (error.status === 409) {
      return observableThrowError('No se pudo actualizar, corriga los parámetros requeridos o las dependencias inválidas e intente nuevamente');
    }

  }

  getUserById(id: number): Observable<any> {
    // Http Headers
      //  this.httpOptions = {
     //headers: this.baseService.createAuthorizationHeader()
    // }
    // console.log(this.httpOptions);
   return this.http.get(this.baseService.HOST + '/user/' + id, this.httpOptions)
   .pipe(
     map(this.baseService.extractData),
     catchError(this.baseService.handleError)
     );
  }

  recoverPassforgot(request: {email: string}): Observable<any> { 
		return this.http.post(this.baseService.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
  }

  newRecoverPasswordForgot(json : any): Observable<any> { 
		return this.http.post(this.baseService.HOST + '/admin/user/resetPassword', json)
			.pipe(catchError(this.handleError));
  }
  
  recoverPassReset(request: {pin: string, newPassword: string}): Observable<any> {
		return this.http.post(this.baseService.HOST + '/user/recoverPassword', request)
			.pipe(catchError(this.handleError));
	}
  

}
