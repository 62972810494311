import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor( private snack : MatSnackBar) { }

	success(msg?:string, title?:string){
		//this.service.success(msg,title);
		this.snack.open(msg, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}
	
	errorToast(msg?:string, title?:string){
		//this.service.error(msg,title);
		this.snack.open(msg, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	info(title?:string,msg?:string){
		//this.service.warning(msg,title);
		this.snack.open(msg, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	show(msg?:string, title?:string){
		//this.service.show(msg,title);
		this.snack.open(msg, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	sucessInsert(name?: string) {
		var msg ;
		if (name) {
			//this.service.success(`¡El registro '${name}' ha sido creado`, 'Creado Correctamente');
			msg = `¡El registro '${name}' ha sido creado`;
		} else {
			//this.service.success( `El(los) registro(s) han sido creado(s)`,'Creado(s) Correctamente');
			msg = `El(los) registro(s) han sido creado(s)`;
		}
		this.snack.open(msg, 'Creado Correctamente', {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	sucessUpdate(name?: string) {
		var msg;
		if (name) {
			//this.service.success(`¡El registro '${name}' ha sido actualizado!`,'Actualizado Correctamente');
			msg = `¡El registro '${name}' ha sido actualizado!`;
		} else {
			//this.service.success(`El(los) registro(s) han sido creado(s)`,'Creado(s) Correctamente');
			msg = `El(los) registro(s) han sido creado(s)`;
		}
		this.snack.open(msg, 'Actualizado Correctamente', {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	sucessDelete(name?: string) {
		var msg;
		if (name) {
			//this.service.success(`¡El registro '${name}' ha sido borrado!`,'Borrado Correctamente', );
			msg = `¡El registro '${name}' ha sido borrado!`;
		} else {
			//this.service.success(`¡El(los) registro(s) han sido borrado(s)!`,'Borrado Correctamente');
			msg = `¡El(los) registro(s) han sido borrado(s)!`;
		}
		this.snack.open(msg, 'Borrado Correctamente', {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	sucessLogin() {
		console.log("ESTE DEBERIA SER EL MENSAJE");
		
		//this.service.success('Ha ingresado correctamente','Login Exitoso');
		this.snack.open('Ha ingresado correctamente', 'Login Exitoso', {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });

	}

	errorDuplicated(name: string) {
		//this.service.error(`El registro '${name}' ya existe en el sistema`,'Registro duplicado');
		this.snack.open(`El registro '${name}' ya existe en el sistema`, 'Registro duplicado', {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	error(msg = 'Hubo un problema. Intentelo mas tarde') {
		//this.service.error( msg,'Error');
		this.snack.open(msg, 'Error', {
			duration: 5000,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	alert(msg?: string) {
		//this.service.warning( msg , 'Notificación');
		this.snack.open(msg, 'Notificación', {
			duration: 5000,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	genericsuccess(title: string, body: string) {
		//this.service.success(body,title);
		this.snack.open(body, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}

	genericerror(title: string, body: string) {
		//this.service.error(body,title);
		this.snack.open(body, title, {
			duration: 2500,
			horizontalPosition: 'center',
			verticalPosition: 'bottom',
		  });
	}
}
