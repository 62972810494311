import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserRoleGuard } from 'app/shared/guards/user-role.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Tablero', breadcrumb: 'Tablero'}
      },
      {
        path: 'partner',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/partner/partner.module').then(m => m.PartnerModule),
        data: { title: 'Compañías', breadcrumb: 'Compañías', roles: "partner.id.user"}
      },
      {
        path: 'sucursal',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/branch/branch.module').then(m => m.BranchModule),
        data: { title: 'Sucursales', breadcrumb: 'Sucursales', roles: "partner.id.user"}
      },
      {
        path: 'file',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/file/file.module').then(m => m.FileModule),
        data: { title: 'Archivos', breadcrumb: 'Archivos', roles: "processfile.all"}
      },
      {
        path: 'enrollment',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/enrollment/enrollment.module').then(m => m.EnrollmentModule),
        data: { title: 'Inscripción', breadcrumb: 'Inscripción', roles: "list.enrollment"}
      },
      {
        path: 'security',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/security/security.module').then(m => m.SecurityModule),
        data: { title: 'Seguridad', breadcrumb: 'Seguridad', roles: "security"}
      },
      {
        path: 'configuration',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/configuration/configuration.module').then(m => m.ConfigurationModule),
        data: { title: 'Configuración', breadcrumb: 'Configuración', roles: "configurations"}
      },
      {
        path: 'report',
        canActivate: [UserRoleGuard],
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule),
        data: { title: 'Reportes', breadcrumb: 'Reportes', roles: "reports"}
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Perfil', breadcrumb: 'Perfil'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      },
      
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

