import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Directive, HostBinding, Inject, Input, OnInit, OnDestroy } from '@angular/core';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService  implements OnInit, OnDestroy{
  roles = [];
  iconMenu: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'icon',
      tooltip: 'Home',
      icon: 'home',
      state: 'dashboard'
    },
    {
      name: 'PROFILE',
      type: 'icon',
      tooltip: 'Profile',
      icon: 'person',
      state: 'profile'
    },
    {
      type: 'separator',
      name: 'Main Items'
    }
  ];

  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {
    
  }

  deleteItems(){
    this.iconMenu = [];
  }

  menuOptions(){
    if(localStorage.getItem('currentData') != null){
      this.roles = JSON.parse(localStorage.getItem('currentData')).user.authUser.roles; // tomamos los roles del usuario en sesion
      for(let rol of this.roles){ // comprobamos los permisos necesacrios para ver las opciones
        if(rol.permissions.find(x => x.identifier === "partner.id.user") != undefined && rol.permissions.find(x => x.identifier === "reports") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "partner.id.user".toUpperCase()) && this.iconMenu.find(x => x.state === "partner") == undefined ){
          this.iconMenu.push({
            name: 'Compañías',
            type: 'link',
            tooltip: 'Compañías',
            svgIcon: 'ulb_partnership',
            state: 'partner'
          });
          this.iconMenu.push({
            name: 'Sucursales',
            type: 'link',
            tooltip: 'Sucursales',
            svgIcon: 'ulb_partnership',
            state: 'sucursal'
          })
        }
  
        if(rol.permissions.find(x => x.identifier === "processfile.all") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "processfile.all".toUpperCase()) && this.iconMenu.find(x => x.state === "file") == undefined ){
          this.iconMenu.push({
            name: 'Generar cobros',
            type: 'link',
            svgIcon: 'ulb_documents',
            state: 'file'
          });
        }
  
  
        if(rol.permissions.find(x => x.identifier === "list.enrollment") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "list.enrollment".toUpperCase()) && this.iconMenu.find(x => x.state === "enrollment") == undefined ){
          this.iconMenu.push({
            name: 'Importar clientes',
            type: 'link',
            svgIcon: 'ulb_person',
            state: 'enrollment'
          });
        }
  
        if(rol.permissions.find(x => x.identifier === "security") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "security".toUpperCase()) && this.iconMenu.find(x => x.state === "security") == undefined ){
          this.iconMenu.push({
            name: 'Seguridad',
            type: 'dropDown',
            tooltip: 'Seguridad',
            svgIcon: 'ulb_home_security',
            state: 'security',
            sub: [
              { name: 'Roles', state: 'roles' },
              { name: 'Usuarios', state: 'users'}
            ]
          });
        }

        if(rol.permissions.find(x => x.identifier === "billingsetting.all") != undefined && rol.permissions.find(x => x.identifier === "security") === undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "billingsetting.all".toUpperCase())  && this.iconMenu.find(x => x.state === "configuration/billings") == undefined ){
          this.iconMenu.push({
            name: 'Renovaciones',
            type: 'link',
            svgIcon: 'ulb_person',
            state: 'configuration/billings'
          });
        }
  
        if(rol.permissions.find(x => x.identifier === "configurations") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "configurations".toUpperCase()) && rol.permissions.find(x => x.identifier === "security") != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === "security".toUpperCase())  && this.iconMenu.find(x => x.state === "configuration") == undefined ){
          this.iconMenu.push({
            name: 'Configuraciones',
            type: 'dropDown',
            tooltip: 'Seguridad',
            svgIcon: 'ulb_settings',
            state: 'configuration',
            sub: [
              { name: 'Mensajería', type: 'dropDown',
              sub: [
                { name: 'Categorías', state: 'categories' },
                { name: 'Plantillas', state: 'templates' },
                { name: 'Listas de contacto', state: 'contacts' },
                { name: 'Campañas', state: 'campaings' }
              ] },
              { name: 'FAC', state: 'fac' },
              { name: 'Frecuencias', state: 'frecuencies' },
              { name: 'Renovaciones', state: 'billings'},
              { name: 'Demonios', state: 'jobs' },
              { name: 'Tablero', state: 'dashboard' },
              { name: 'Integraciones', state: 'integrations' },
              { name: 'Bancos', state: 'banks' },
              { name: 'Canales', state: 'channels' },
              { name: 'Notificaciones', state: 'notifications' }
            ]
          });
        }
  
        if(rol.permissions.find(x => x.identifier === "reports") != undefined  && rol.permissions.find(x => x.identifier.toUpperCase() === "reports".toUpperCase()) && this.iconMenu.find(x => x.state === "report") == undefined ){
          this.iconMenu.push({
            name: 'Reportes',
            type: 'dropDown',
            tooltip: 'Seguridad',
            svgIcon: 'ulb_database',
            state: 'report',
            sub: [
              { 
                name: 'Facturas', type: 'dropDown',
                sub: [
                  { name: 'Facturas por cobrar', state: 'invoices-to-charge' },
                  { name: 'Facturas por fecha de creación', state: 'invoices-date-created' },
                  { name: 'Facturas por fecha de cobro', state: 'invoices-date-charged'},
                  { name: 'Facturas cobradas', state: 'invoices-charged' },
                  { name: 'Facturas por cliente', state: 'customer-invoices' },
                  { name: 'Actualizar estado de facturas', state: 'update-invoices' },
                ] 
              },
              { 
                name: 'Bitácoras', type: 'dropDown',
                sub: [
                  { name: 'Bitácora de renovaciones', state: 'billingsettings' },
                  { name: 'Bitácora de recobros', state: 'rebillingsettings' },
                ] 
              },
              { 
                name: 'Clientes', type: 'dropDown',
                sub: [
                  { name: 'Clientes por plan', state: 'customers-by-plan' },
                  { name: 'Clientes por compañía', state: 'customers-by-company' },
                ] 
              },
              { name: 'Notificaciones Fallidas', state: 'notification-failed' }
              ,
              { 
                name: 'Auditoria', type: 'dropDown',
                sub: [
                  { name: 'Auditoria API', state: 'audition-api' },
                  { name: 'Auditoria INFO', state: 'audition-info' },
                ] 
              },
              { 
                name: 'Reportes de campañas', type: 'dropDown',
                sub: [
                { name: 'Global', state: 'global' },
                { name: 'Global por día', state: 'global-by-day' },
                { name: 'Resultados por campaña ', state: 'global-token-success' },
                { name: 'Por categorías', state: 'by-categories' },
                { name: 'Whatsapp', state: 'whatsapp' },
                { name: 'Whatsapp Global ', state: 'whatsapp-scoppe' },
                
                ] 
              }
            ]
          });
        }
      }
      }
  }

  ngOnInit() {}
  ngOnDestroy() {
    console.log("DESTROY")
  }

  
  // You can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    
    this.menuItems.next(this.iconMenu);

  }
}
