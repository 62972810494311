<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/Logo_Transparente.png" alt="" class="app-logo">
                <span class="app-logo-text">Billcentrix</span>
                
                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div 
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()" 
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>