import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { NotificationService} from '../services/notification.service';

@Injectable()
export class UserRoleGuard implements CanActivate {
  
  constructor(private router: Router, private jwtAuth: JwtAuthService, private notificationService: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var user = JSON.parse(localStorage.getItem('currentData')).user; // tomamos el usuario en sesion
    var found = false; // variable para controlar el acceso
    for( let rol of user.authUser.roles){ // Buscamos dentro de los roles del usuario el rol permitido para esta pantalla
      if(rol.permissions.find(x => x.identifier === route.data.roles) != undefined && rol.permissions.find(x => x.identifier.toUpperCase() === route.data.roles.toUpperCase())){
        found = true;
      }
    }
    if ( user && route.data && route.data.roles && found) { // solo si todo es correcto, damos acceso
      return true;
    } else { // sino entonces 
      this.notificationService.error('Usted no posee permisos suficientes para aceder a esta pantalla!');
      return false;
    }
  }
}
